<template>
  <div class="prime-settings-view prime-boxed-container" v-if="currentPatient">
    <div>
      <h4>General Information</h4>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>First Name</h6>
          </div>
          <div class="prime-form-block-input input-group">
            <select disabled id="title" class="form-control input-sm" v-model="currentPatient.payload.account.title" @change="formIsChanged = true">
              <option v-for="option in copy.enrollmentForm.generalInformation.titleOptions" :key="option" :selected="option === currentPatient.payload.account.title" :value="option">{{ option }}</option>
            </select>
            <input disabled class="form-control" type="text" placeholder="First Name" v-model.trim="currentPatient.payload.account.firstName" @change="formIsChanged = true" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Last Name</h6>
          </div>
          <div class="prime-form-block-input input-group">
            <input disabled class="form-control" type="text" placeholder="Last Name" v-model.trim="currentPatient.payload.account.lastName" @change="formIsChanged = true" />
            <select disabled id="title" class="form-control input-sm" v-model="currentPatient.payload.account.suffix" @change="formIsChanged = true">
              <option v-for="option in copy.enrollmentForm.generalInformation.suffixOptions" :key="option" :selected="option === currentPatient.payload.account.suffix" :value="option">{{ option }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Date of Birth</h6>
          </div>
          <div class="prime-form-block-input">
            <div class="prime-v-date-picker-container">
              <v-date-picker
                :value="null"
                style="pointerEvents:none;"
                v-model="inFormattedDateofBirth"
                :input-debounce="500"
                :popover="{
                  visibility: 'click'
                }"
                :input-props="{
                  class: 'form-control'
                }"
                class="prime-v-date-picker"
              />
              <ion-icon name="md-calendar" id="dob-icon"></ion-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Patient ID</h6>
          </div>
          <div class="prime-form-block-input">
            <input disabled class="form-control" type="text" placeholder="ex: 1234567" v-model.trim="currentPatient.payload.beneficiary.mrn" @change="formIsChanged = true" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Timezone</h6>
          </div>
          <div class="prime-form-block-input width-320">
            <select disabled id="timezone" class="form-control" @change="handleTimeZoneSelectChange($event)">
              <option v-for="option in copy.enrollmentForm.communicationPrefTimes.timeZones" :key="option.label" :selected="option.value === currentPatient.payload.account.timeZone" :value="option.value">{{ option.label }}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Address Line 1</h6>
          </div>
          <div class="prime-form-block-input width-320">
            <input disabled v-model.trim="address1" class="form-control" type="text" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Address Line 2 (optional)</h6>
          </div>
          <div class="prime-form-block-input width-320">
            <input disabled v-model.trim="address2" class="form-control" type="text" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>City</h6>
          </div>
          <div class="prime-form-block-input width-320">
            <input disabled v-model.trim="city" class="form-control" type="text" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>State / Zip Code</h6>
          </div>
          <div class="prime-form-block-input input-group width-320">
            <select disabled class="form-control input-sm state" v-model="state">
              <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
            </select>
            <input disabled class="form-control" type="text" v-model.trim="zipCode" />
          </div>
        </div>
      </div>

      <h4 class="margin-top-fourty">LumerisEngage Settings</h4>
      <!-- {{currentPatient.payload}} -->
      <div v-if="errors.length > 0" class="errors">
        <IconWarning />
        <ion-text v-for="error in errors" :key="error">{{ error + " " }}</ion-text>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row keep-input-inline">
          <div class="prime-form-block-title">
            <h6>Enrollment Status</h6>
          </div>
          <div class="prime-form-block-input width-250">
            <EnrollmentTool :smsNumber="phoneNumberSMS" :enrollmentId="enrollmentId" :enrollmentStatus="enrollmentStatus || 'notEnrolled'" :beneficiaryId="beneficiary.id" :beneficiaryLastName="account.lastName" />
          </div>
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row keep-input-inline">
          <div class="prime-form-block-title">
            <h6>Priority Patient</h6>
          </div>
          <div class="prime-form-block-input">
            <span v-if="beneficiary.vip == true">Yes</span><span v-else>No</span>
            <ion-toggle color="primary" mode="ios" @ionChange="beneficiary.vip = !beneficiary.vip" :checked="beneficiary.vip" value="vip"></ion-toggle>
          </div>
        </div>
        <div class="prime-form-block-description">
          When a patient is marked Priority their incoming messages will be automatically set to High Priority.
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Phone Number - Text Messages</h6>
          </div>
          <div class="prime-form-block-input width-250">
            <PrimePhoneInput disabled v-model.trim="phoneNumberSMS" type="twilio" />
          </div>
        </div>
        <div class="prime-form-block-description">
          This number must support SMS messaging.
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Phone Number - Voice Calls</h6>
          </div>
          <div class="prime-form-block-input width-250">
            <PrimePhoneInput disabled v-model.trim="phoneNumberVoice" type="twilio" />
          </div>
        </div>
        <div class="prime-form-block-description">
          This is the phone number at which the patient would like to receive voice calls from the system, and live from the care team.
        </div>
      </div>
      <div class="prime-form-block-container">
        <div class="prime-form-block-title-row">
          <div class="prime-form-block-title">
            <h6>Email Address</h6>
          </div>
          <div class="prime-form-block-input width-250">
            <PrimeEmailInput disabled v-model.trim="email" type="email" />
          </div>
        </div>
        <div class="prime-form-block-description">
          The patient’s email address at which they will receive reminders, notifications, and educational content.
        </div>
      </div>

      <button v-if="$can(I.MODIFY_PATIENT)" @click="checkForm()" class="prime-button button-block fade-in" style="margin-top:20px;" :class="{ 'button-pending': loading }">
        <span>Save Updates</span>
        <ion-spinner name="dots" duration="1200" />
      </button>
      <button v-else class="prime-button disabled button-block fade-in" style="margin-top:20px;" :class="{ 'button-pending': loading }" v-tooltip.top-start="{ content: $orgMissingPermissions('The Modifying Patient feature') }">
        <span>Save Updates</span>
        <ion-spinner name="dots" duration="1200" />
      </button>
    </div>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { calendar } from "ionicons/icons";
import isValidPhoneNumber from "@/utils/FormHelpers/isValidPhoneNumber";
import formatPhoneNumber from "@/utils/FormHelpers/formatPhoneNumber";
import PrimePhoneInput from "@/components/Global/PrimePhoneInput";
import PrimeEmailInput from "@/components/Global/PrimeEmailInput";
import { send as httpSend } from "@/services/Api";
import states from "@/assets/languagePack/states";
import IconWarning from "@/components/Global/Icons/IconWarning";
import EnrollmentTool from "@/components/Tools/EnrollmentTool/EnrollmentTool";

addIcons({
  "ios-calendar": calendar.ios,
  "md-calendar": calendar.md
});

export default {
  name: "Settings",
  components: {
    IconWarning,
    EnrollmentTool,
    PrimePhoneInput,
    PrimeEmailInput
  },
  props: {
    currentPatient: Object
  },
  data() {
    return {
      loading: false,
      communicationPrefTimes: this.$languagePack.enrollmentForm.communicationPrefTimes,
      newAccountChannel: { email: undefined, sms: undefined, voice: undefined },
      errors: [],
      copy: this.$languagePack,
      states: states.codes
    };
  },
  computed: {
    email: {
      get() {
        return this.getCommChannel("email");
      },
      set(val) {
        this.updateCommChannel("email", val);
      }
    },
    phoneNumberSMS: {
      get() {
        return this.getCommChannel("sms");
      },
      set(val) {
        this.updateCommChannel("sms", val);
      }
    },
    phoneNumberVoice: {
      get() {
        return this.getCommChannel("voice");
      },
      set(val) {
        this.updateCommChannel("voice", val);
      }
    },
    account() {
      return this.currentPatient.payload.account;
    },
    address1() {
      return this.currentPatient.payload.address ? this.currentPatient.payload.address.address1 : null;
    },
    address2() {
      return this.currentPatient.payload.address ? this.currentPatient.payload.address.address2 : null;
    },
    city() {
      return this.currentPatient.payload.address ? this.currentPatient.payload.address.city : null;
    },
    zipCode() {
      return this.currentPatient.payload.address ? this.currentPatient.payload.address.zipCode : null;
    },
    state() {
      return this.currentPatient.payload.address ? this.currentPatient.payload.address.state : null;
    },
    beneficiary() {
      return this.currentPatient.payload.beneficiary;
    },
    enrollmentId() {
      return this.currentPatient.payload.enrollmentId;
    },
    enrollmentStatus() {
      return this.currentPatient.payload.enrollmentStatus;
    },
    inFormattedDateofBirth: {
      // getter
      get: function() {
        var dateFromServer = this.currentPatient.payload.beneficiary.dob;
        var dateForUi = this.$moment(dateFromServer).toDate();

        return dateForUi;
      },
      // setter
      set: function(dateFromUI) {
        var dateForServer = this.$moment.utc(dateFromUI).format("YYYY-MM-DD");
        this.currentPatient.payload.beneficiary.dob = dateForServer;
        this.formIsChanged = true;
      }
    }
  },
  methods: {
    validatePhone: function(channel, data) {
      this.errors = [];
      if (!isValidPhoneNumber(data)) {
        this.errors.push("Please enter a valid 10 digit phone number");
      } else {
        this.updateCommChannel(channel, formatPhoneNumber(data));
      }
    },
    getCommChannel: function(channel) {
      const found = this.currentPatient.payload.accountChannels.find(el => el.channel === channel);
      return found ? found.identifier : null;
    },
    updateCommChannel: function(channel, value) {
      const found = this.currentPatient.payload.accountChannels.find(el => el.channel === channel);
      if (found == undefined && value != "") {
        this.newAccountChannel[channel] = {
          accountId: this.currentPatient.payload.beneficiary.accountId,
          channel: channel,
          id: null,
          identifier: value,
          priority: 0,
          provider: null,
          status: "active"
        };
      }
      return found ? (found.identifier = value) : null;
    },
    addNewAccountChannel: function(request) {
      this.loading = true;
      const method = "post";
      const path = document.config.SITUpdateUserData;
      const payload = request;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          //this.currentPatient = result.data;
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "New account channel was created",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.loading = false;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not create new account channel. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    },
    sendPayload: function() {
      this.loading = true;
      const method = "put";
      const path = document.config.patientSettings;
      const payload = this.currentPatient.payload;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "Patient Settings have been updated",
              duration: 1000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          this.loading = false;
        })
        .then(() => {
          for (let i = 0; i < Object.keys(this.newAccountChannel).length; i++) {
            if (this.newAccountChannel[Object.keys(this.newAccountChannel)[i]] != undefined && this.newAccountChannel[Object.keys(this.newAccountChannel)[i]].identifier) {
              this.addNewAccountChannel(this.newAccountChannel[Object.keys(this.newAccountChannel)[i]]);
            }
          }
        })
        .catch(error => {
          var errorMsg = "Could not save Patient Settings. Please try again later.";

          if (error.response.status == "400") {
            errorMsg = error.response.data.message;
          }

          this.$ionic.toastController
            .create({
              header: "Error",
              message: errorMsg,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());

          khanSolo.log(error);
          this.loading = false;
        });
    },
    checkForm: function() {
      if (this.validateEmail()) {
        this.sendPayload();
      } else if (this.email == "" || this.email == undefined) {
        this.sendPayload();
      } else {
        this.errors.push("Please enter a valid email address");
      }
    },
    validateEmail: function() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  mounted() {
    this.phoneNumberSMS = this.getCommChannel("sms", "identifier");
    this.phoneNumberVoice = this.getCommChannel("voice", "identifier");
    this.email = this.getCommChannel("email", "identifier");
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.errors {
  color: var(--ion-color-danger);
  font-weight: 500;
  background: var(--ion-color-danger-contrast);
  padding: 15px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 10px;
}
.errors svg {
  fill: var(--ion-color-danger);
  width: 32px;
  margin-right: 10px;
}
.prime-form-block-container .prime-form-block-input span {
  font-size: 12px;
  font-weight: 700;
  margin-right: 10px;
}
.prime-form-block-container .prime-form-block-input span.prime-v-date-picker {
  margin-right: 0 !important;
}
.input-sm {
  width: 120px;
}
h6 {
  font-weight: 500 !important;
}
select[disabled] {
  opacity: 1;
}
</style>
