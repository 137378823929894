<template>
  <input v-bind:value="formattedValue" @input="onInput" v-mask="'email@example.com'" class="form-control" type="email" placeholder="email@example.com" />
</template>

<script>
export default {
  name: "PrimeEmailInput",
  props: {
    value: String,
    type: String
  },
  data() {
    return {
      rawValue: this.value
    };
  },
  watch: {
    value(newValue) {
      this.rawValue = newValue;
    }
  },
  computed: {
    formattedValue() {
      if (!this.value) {
        return undefined;
      }
      return this.value.trim();
    }
  },
  methods: {
    onInput(event) {
      this.rawValue = event.target.value;
      this.$emit("input", this.rawValue);
    }
  }
};
</script>
